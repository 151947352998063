import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import handleHospital from "@/services/modules/hospital";
import useDate from '@/services/utils/day';
import { isArray, isEmpty } from 'lodash';
import { convertToTitleCase } from '@/services/utils/global';

pdfMake.fonts = {
    SulaimanLipi: {
        normal: 'https://fonts.cdnfonts.com/s/14639/solaimanlipi.woff',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
    }
}

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const pdfPrinter = () => {
    const { formatDate } = useDate();
    const { calculateAge } = handleHospital();

    const exportToPDF = (company, admissionInfo, qrCode, barcodePatient, barcodeAdmission) => {

        const phcGeneral = admissionInfo.phc_general;

        var doc = {
            pageSize: 'A4',
            pageMargins: [ 25, 150, 25, 48 ],
            header: {},
            footer: {},
            content: getContent(doc, admissionInfo, phcGeneral, barcodePatient, barcodeAdmission),
            styles : {
                header: {
                    fontSize: 24,
                }
            },
            defaultStyle: {
                color: 'black',
                fontSize: 10,
                font: 'SulaimanLipi'
            },
            info: {
                title: 'IPD Admission'
            }
        }

        const pdfDocGenerator = pdfMake.createPdf(doc);
        pdfDocGenerator.open();
    }

    const getContent = (doc, admissionInfo, phcGeneral, barcodePatient, barcodeAdmission) => {
        return [
            {
                text: 'IPD Admission',
                style: 'header',
                alignment: 'center',
                margin: [0, 10, 20, 10],
                bold: true,
                color: 'black',
            },
            {
                margin: [0, 20, 0, 0],
                alignment: 'justify',
                columns: [
                    {
                        width: '50%',
                        stack: [
                            {
                                svg: barcodePatient
                            },
                            {
                                text: `Patient Name: ${admissionInfo.patient.full_name}`,
                                alignment: 'left',
                                width: '50%'
                            },
                            {
                                text: `Patient ID: ${admissionInfo.patient.serial_no}`,
                                alignment: 'left',
                                width: '50%'
                            },
                            {
                                text: `${(admissionInfo.patient && admissionInfo.patient.birthday) ? 
                                        'Age: ' + calculateAge(admissionInfo.patient.birthday) : ''}` + 
                                        `${(admissionInfo.patient && admissionInfo.patient.gender) ? 
                                        ' | Sex: ' + admissionInfo.patient.gender : '' }`,
                                alignment: 'left',
                                width: '50%'
                            },
                            {
                                text: `Patient Phone No: ${admissionInfo.patient.mobile_no}`,
                                alignment: 'left',
                                width: '50%'
                            },
                            {
                                text: `${admissionInfo.patient.full_address ? 'Patient Address: ' + admissionInfo.patient.full_address : ''}`,
                                alignment: 'left',
                                width: '50%'
                            },
                        ]
                    },
                    {
                        alignment: 'right',
                        stack: [
                            {
                                svg: barcodeAdmission
                            },
                            {
                                text: `Admission No: ${admissionInfo.admission_no}`
                            },
                            {
                                text: `Admission Date: ${admissionInfo.formattedAdmissionData}`
                            },
                            admissionInfo.department ? {
                                text: `Department: ${admissionInfo.department.name}`
                            } : null,
                            admissionInfo.nurse ? {
                                text: `Nurse: ${admissionInfo.nurse.full_name}`
                            } : null
                        ]
                    },
                ]
            },
            {
                canvas: [
                    {
                        type: 'line',
                        x1: 0, 
                        y1: 0, 
                        x2: 540,
                        y2: 0,
                    },
                ],
                margin: [0, 5]
            },
            {
                margin: [0, 5], 
                columns: [
                    admissionInfo.human_resource ? {
                        width: "49%",
                        stack: [
                            {
                                text: 'Referred Doctor',
                                fontSize: 12,
                                bold: true,
                                decoration: 'underline',
                                margin: [2, 5]
                            },
                            {
                                text: admissionInfo.human_resource.name
                            }
                        ]
                    } : null,

                    admissionInfo.admitted_by_user ? {
                        width: "49%",
                        stack: [
                            {
                                text: 'Admitting Doctor',
                                fontSize: 12,
                                bold: true,
                                decoration: 'underline',
                                margin: [2, 5]
                            },
                            {
                                text: admissionInfo.admitted_by_user.full_name
                            }
                        ]
                    } : null
                ]
            },
            
            phcGeneral ? {
                stack: [
                    {
                        text: 'PHC Note',
                        fontSize: 12,
                        bold: true,
                        decoration: 'underline',
                        margin: [0, 5]
                    },
                    {
                        margin: [10, 0, 0, 0],
                        stack: [
                            { text: `${phcGeneral.height ? 'Height (CM): ' +  phcGeneral.height : ''}` },
                            { text: `${phcGeneral.weight ? 'Weight (KG): ' +  phcGeneral.weight : ''}` },
                            { text: `${phcGeneral.type_of_weight ? 'Type of Weight: ' +  convertToTitleCase(phcGeneral.type_of_weight) : ''}` },
                            { text: `${phcGeneral.normal_weight ? 'Normal Weight (KG): ' +  phcGeneral.normal_weight : ''}` },
                            { text: `${phcGeneral.bmi ? 'BMI: ' +  phcGeneral.bmi : ''}` },
                            { text: `${phcGeneral.blood_pressure ? 'Blood Pressure (mmHg): ' +  phcGeneral.blood_pressure : ''}` },
                            { text: `${phcGeneral.blood_pressure_type ? 'Type of Blood Pressure: ' +  phcGeneral.blood_pressure_type : ''}` },
                            { text: `${phcGeneral.oxygen_level ? 'Oxygen level (%): ' +  phcGeneral.oxygen_level : ''}` },
                            { text: `${phcGeneral.pulse ? 'Pulse (b/Min): ' +  phcGeneral.pulse : ''}` },
                            { text: `${phcGeneral.blood_group ? 'Blood Group: ' +  convertToTitleCase(phcGeneral.blood_group) : ''}` }
                        ].filter(Boolean)
                    }
                ]
            } : null,

            ... getNotes(admissionInfo.notes),
        ]
    }

    const getNotes = (allNotes) => {
        if(! isArray(allNotes) || isEmpty(allNotes)) return [];
        
        return allNotes.map(note => {
            return getNote(note.type, note.note);
        })
    }

    const getNote = (type, notes) => {

        if(type === 'investigation_history') {
            notes = notes.map(note => {
                return note.name + (note.result ? ` - ( ${note.result} )` : '')
            })
        }

        return {
            stack: [
                {
                    text: convertToTitleCase(type),
                    fontSize: 12,
                    bold: true,
                    decoration: 'underline',
                    margin: [0, 5]
                },
                {
                    margin: [10, 0, 0, 0],
                    ol: notes
                }
            ]
        }
    }

    return {
        exportToPDF
    }
}

export default pdfPrinter;
