<template>
    <div class="row">
        <div class="col-12 col-md-12 note-head">
            <div class="note-title">
                <h4>Phc Note</h4>
            </div>
            <div class="note-btn">
                <button @click="onOpenModal" type="button" class="btn btn-outline-primary btn-sm btn-block">
                    <i class="fas fa-plus"></i>
                </button>
            </div>
        </div>
        <hr>
        <div class="col-12 col-md-12 note-body">
            <div class="card">
                <div class="card-body">
                    <ul v-if="note" class="no-dots">
                        <li><strong>Height (CM): </strong> {{ note.height }}</li>
                        <li><strong>Weight (KG): </strong> {{ note.weight }}</li>
                        <li><strong>Type of Weight: </strong> {{ note.type_of_weight }}</li>
                        <li><strong>Normal Weight (KG): </strong> {{ note.normal_weight }}</li>
                        <li><strong>BMI: </strong> {{ note.bmi }}</li>
                        <li><strong>Blood Pressure (mmHg): </strong> {{ note.blood_pressure_uppper }} - {{ note.blood_pressure_lower }}</li>
                        <li><strong>Type of Blood Pressure: </strong> {{ note.blood_pressure_type }}</li>
                        <li><strong>Oxygen level (%): </strong> {{ note.oxygen_level }}</li>
                        <li><strong>Pulse (b/Min): </strong> {{ note.pulse }}</li>
                        <li><strong>Blood Group: </strong> {{ note.blood_group }}</li>
                    </ul>
                </div>
            </div>
        </div>
        <Loader v-if="loading"/>
    </div>
</template>

<script setup>
import { ref } from "vue"
import Loader from '@/components/atom/LoaderComponent'

const props = defineProps({
    note: {
        type: Object || null,
        default: null
    },
    openModal: Function
})

const emit = defineEmits(['openModal'])
const loading = ref(false);

const onOpenModal = () => {
    emit('openModal')
}
</script>

<style scoped>
.no-dots {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.note-title {
    min-width: 200px;
}
.note-body .card-body{
    padding: 0 2%;
}
.note-head {
    display: flex;
    margin-top: 2%;
    justify-content: space-between;
    margin-bottom: 1%;
}
.action .close {
    color: red;
    cursor: pointer;
}
.note-body .card {
    background: #f9f9f9;
    min-height: 100px;
    margin-bottom: 1rem;
}
</style>