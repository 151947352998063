<template>
    <div class="modal fade show add-note-modal" aria-labelledby="addNewCardTitle">
        <div class="modal-dialog modal-dialog-centered modal-lg" ref="admissionModalRef">
            <div class="modal-content">
                <div class="row">
                    <div class="col-12">
                        <div class="d-flex justify-content-between align-items-center px-1 my-1 align-item-center">
                            <h1 class="mb-0">Primary Healthcare Card- Update</h1>
                            <button 
                                @click="closeModal" type="button" 
                                class="btn-close" data-bs-dismiss="modal"
                                aria-label="Close">
                            </button>
                        </div>
                    </div>
                </div>
                <hr class="mt-0">
                <div class="modal-body">
                    <div class="col-12 row justify-content-between mx-auto">
                        <div class="row mx-auto">
                            <div class="col-md-6">
                                <label class="form-label" for="height">Height (CM)</label>
                                <vField
                                    v-model="formData.height"
                                    name="height"
                                    type="number"
                                    class="form-control"
                                />
                            </div>
                            <div class="col-md-6">
                                <div class="row my-1 my-md-2">
                                    <div class="col-6">
                                        <vField
                                            :placeholder="formData.height ? convertHeightToFeetAndInches(formData.height).feet : 'Feet'"
                                            name="amount text-right"
                                            type="number"
                                            class="form-control text-right"
                                            readonly
                                        />
                                    </div>
                                    <div class="col-6">
                                        <vField
                                            :placeholder="formData.height ? convertHeightToFeetAndInches(formData.height).inches : 'Inches'"
                                            name="amount text-right"
                                            type="number"
                                            class="form-control text-right"
                                            readonly
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mx-auto mb-md-2 mb-1">
                            <div class="col-md-6 mb-1 my-md-0">
                                <label class="form-label" for="weight">Weight (KG)</label>
                                <vField
                                    v-model="formData.weight"
                                    name="weight"
                                    type="number"
                                    class="form-control"
                                />
                            </div>
                            <div class="col-md-6">
                                <label class="form-label" for="typeOfBloodPressure">Type of Weight</label>
                                <v-select
                                    placeholder="Select type of weights"
                                    class="w-100"
                                    v-model="formData.type_of_weight"
                                    :options="weightTypes"
                                    label="name"
                                    :reduce="name => name.value"
                                />
                            </div>
                        </div>
                        <div class="row mx-auto mb-md-2 mb-1">
                            <div class="col-md-6 mb-1 my-md-0">
                                <label class="form-label" for="normalWeight">Normal Weight (KG)</label>
                                <vField
                                    v-model.trim="formData.normal_weight"
                                    name="normalWeight"
                                    type="text"
                                    class="form-control"
                                />
                            </div>
                            <div class="col-md-6">
                                <label class="form-label" for="bmi">BMI</label>
                                <vField
                                    v-model="bmiCal"
                                    placeholder="bmi"
                                    name="bmi"
                                    type="number"
                                    class="form-control"
                                    readonly
                                />
                            </div>
                        </div>
                        <div class="row mx-auto mb-md-2 mb-1">
                            <div class="col-md-6 mb-1 my-md-0">
                                <label class="form-label" for="bloodPressure">Blood Pressure (mmHg)</label>
                                <div class="row">
                                    <div class="col-6">
                                        <vField
                                            v-model="formData.blood_pressure_uppper"
                                            placeholder="Upper"
                                            name="amount text-right"
                                            type="number"
                                            class="form-control text-right"
                                        />
                                    </div>
                                    <div class="col-6">
                                        <vField
                                            v-model="formData.blood_pressure_lower"
                                            placeholder="lower"
                                            name="amount text-right"
                                            type="number"
                                            class="form-control text-right"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label class="form-label" for="typeOfBloodPressure">Type of Blood Pressure</label>
                                <v-select
                                    placeholder="Select blood pressure types"
                                    class="w-100"
                                    v-model="formData.blood_pressure_type"
                                    :options="bloodPressureTypes"
                                    label="name"
                                    :reduce="name => name.value"
                                />
                            </div>
                        </div>
                        <div class="row mx-auto mb-md-2 mb-1">
                            <div class="col-md-6 mb-1 my-md-0">
                                <label class="form-label" for="oxygenLevel">Oxygen level (%)</label>
                                <vField
                                    v-model="formData.oxygen_level"
                                    name="normalWeight"
                                    type="number"
                                    class="form-control"
                                />
                            </div>
                            <div class="col-md-6">
                                <label class="form-label" for="pulse">Pulse (b/Min)</label>
                                <vField
                                    v-model="formData.pulse"
                                    name="pulse"
                                    type="text"
                                    class="form-control"
                                />
                            </div>
                        </div>
                        <div class="row mx-auto mb-md-2 mb-1">
                            <div class="col-md-6 mb-1 my-md-0">
                                <label class="form-label" for="bloodGroup">Blood Group</label>
                                <v-select
                                    v-model="formData.blood_group"
                                    placeholder="Select Blood Group"
                                    :options="bloodGroups"
                                    label="name"
                                    :reduce="name => name.value"
                                />
                            </div>
                            <div class="col-md-6">
                                <div class="w-100 py-2">
                                    <button :disabled="isLoading" @click="submit" class="btn btn-primary w-100">Save</button>
                                </div>
                            </div>
                        </div>
                        
                    </div> 
                </div>
            </div>
        </div>
        <Loader v-if="loading"/>
    </div>
</template>

<script setup>
import { useRoute } from "vue-router";
import { onMounted, ref, computed, inject } from 'vue';
import handleHospitalPHC from "@/services/modules/hospital/phc";
import Loader from "@/components/atom/LoaderComponent.vue";
import handleHospital from "@/services/modules/hospital";
import { useStore } from "vuex";
import cloneDeep from "lodash/cloneDeep";

const props = defineProps({
    phcNote: {
        type: Object || null,
        default: null
    },
    onUpdatePhc: {
        type: Function,
        default: () => {}
    }
})

const emit = defineEmits(['onUpdatePhc'])

const store = useStore();
const { fetchBloodPressure } = handleHospitalPHC();
const { convertHeightToFeetAndInches, bmiCalculator } = handleHospital();
const showError = inject('showError');
const isLoading = ref(false);
const loading = ref(true);
const route = useRoute();
const companyId = route.params.companyId;
const bloodGroups = ref([]);
const bloodPressureTypes = ref([]);
const weightTypes = ref([]);

const formData = ref({
    company_id:  companyId,
    service_general_id: null,
    nurse_id: null,
    area_id: null,
    height: 0,
    weight: 0,
    normal_weight: '',
    bmi: 0,
    blood_pressure_uppper: 0,
    blood_pressure_lower: 0,
    blood_pressure_type: null,
    oxygen_level: 0,
    pulse: 0,
    blood_group: null,
    type_of_weight: 'normal'
})

const bmiCal = computed(() => bmiCalculator(formData.value.height, formData.value.weight))

const closeModal = () => {
    store.state.isModalOpenFour = false;
}

const validation = () => {
    if(!formData.value.height){
        showError('Please select height')
        return false
    }
    if(!formData.value.weight){
        showError('Please select weight')
        return false
    }
    return true;
}

const submit = () => {
    if(!validation()){
        return
    }
    formData.value.bmi = bmiCal.value ? bmiCal.value : 0;

    emit('onUpdatePhc', formData.value);
    closeModal();
}

const getBloodPressure = async () => {
    const query = `?company_id=${companyId}`;
    loading.value = true

    await fetchBloodPressure(query).then((res) => {
        if(res.status){
            bloodGroups.value = res.data.bloodGroups;
            bloodPressureTypes.value = res.data.bloodPressureTypes;
            weightTypes.value = res.data.weightTypes;
        }
    })

    loading.value = false;
}

const setEditData = () => {
    if(!props.phcNote) return
    formData.value = cloneDeep(props.phcNote);
}

onMounted(() => {
    getBloodPressure();
    setEditData();
})
</script>

<style scoped>
.modal-body {
    min-height: 400px;
}
.bg {
    background: aliceblue !important;
}
.add-note-modal {
    display: block; 
    background: #1c1d1c33
}
</style>